import { makeAutoObservable } from "mobx";
import { IMyConfirmController } from "../../../interface/component/feedback/mymessage/IMyConfirmController";
import { MessageEnum } from "../../../interface/utils/message/enum/MessageEnum";
import { MyLocale } from "../../locale/MyLocale";

class MyConfirmController implements IMyConfirmController {
  visible: boolean;
  type: MessageEnum;
  title: string | JSX.Element;
  content: string | JSX.Element;
  onOk: () => void;
  onCancel: () => void;
  okText: string | JSX.Element;
  cancelText: string | JSX.Element;
  isShowOk: boolean;
  isShowCancel: boolean;
  constructor() {
    this.visible = false;
    this.type = MessageEnum.Info;
    this.title = "";
    this.content = "";
    this.onOk = () => {};
    this.onCancel = () => (this.visible = false);
    this.okText = <MyLocale value="password" />;
    this.cancelText = <MyLocale value="password" />;
    this.isShowOk = true;
    this.isShowCancel = true;

    makeAutoObservable(this);
  }

  showConfirm(confirm?: {
    type?: MessageEnum;
    title?: string | JSX.Element;
    content?: string | JSX.Element;
    onOk?: () => void;
    onCancel?: () => void;
    okText?: string | JSX.Element;
    cancelText?: string | JSX.Element;
    isShowOk?: boolean;
    isShowCancel?: boolean;
  }) {
    this.type = confirm?.type ?? MessageEnum.Info;
    this.title = confirm?.title ?? <MyLocale value="password" />;
    this.content = confirm?.content ?? "";
    this.onOk =
      confirm?.onOk ??
      (() => {
        this.visible = false;
      });
    this.onCancel =
      confirm?.onCancel ??
      (() => {
        this.visible = false;
      });
    this.okText = confirm?.okText ?? <MyLocale value="password" />;
    this.cancelText =
      confirm?.cancelText ?? <MyLocale value="password" />;
    this.isShowOk = confirm?.isShowOk ?? true;
    this.isShowCancel = confirm?.isShowCancel ?? true;
    this.visible = true;
  }
}

export class MyConfirmControllerSingle {
  public static controller: IMyConfirmController;
  private constructor() {}
  public static createInstance(): IMyConfirmController {
    if (this.controller === undefined) {
      this.controller = new MyConfirmController();
    }
    return this.controller;
  }
}
