import { GetServerSidePropsContext, PreviewData } from "next";
import { ParsedUrlQuery } from "querystring";

export class GlobalContext {
  private constructor() {}
  public static ctx: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>;
  public static setNextResponseContext(
    ctx: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
  ) {
    this.ctx = ctx;
  }
}
