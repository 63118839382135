import axios from "axios";
import { useSession, getSession } from "next-auth/react";
import { useCallback, useState } from "react";
import { RequestUrl } from "../../config/config";
import { ConfirmTypeEnum, useMyConfirm } from "../../context/ConfirmContext";
import { GlobalContext } from "../../global/context/GlobalContext";
import { ILoading } from "../../interface/component/ILoading";
import { IResult } from "../../interface/model/base/IResultT";
import { useLocale } from "../useLocale";

export enum ResponseCode {
  Success = "000000",
  JWT000 = "JWT000",
  JWT001 = "JWT001",
}

interface IMyApi extends Omit<ILoading, "toggleLoading"> {
  post<T>(url: string, data: any): Promise<T | boolean | undefined>;
  uploadFile(url: string, file: File): Promise<string | undefined>;
  data: any;
}

export const useMyApi = (params?: {
  timeout?: number;
  isEnableDefaultMsg?: boolean;
  isDisableTip?: boolean;
  responseAll?: boolean;
}) => {
  const { language } = useLocale();
  const confirm = useMyConfirm();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const myAxios = useCallback(() => {
    const api = axios.create({
      transformRequest: axios.defaults.transformRequest,
      baseURL: RequestUrl,
      timeout: params?.timeout ?? 30_000,
    });
    api.interceptors.request.use(async (config) => {
      const session = await getSession(GlobalContext.ctx);
      if (session) {
        const token = (session as any).accessToken;
        if (token) {
          config.headers!["Authorization"] = token;
        }
      }
      config.headers![
        "X-Exchange-Info"
      ] = `version=1.0&source=web&appname=kooolacreate&language=${
        language ?? "en"
      }`;
      return config;
    });
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.log(error);
        if(error["response"] && error.response.status == 401){
          confirm?.show({
            content: "你的角色没有该功能的操作权限",
            type: ConfirmTypeEnum.Error,
          });
        }
        else if(error.code == "ECONNABORTED"){
          confirm?.show({
            content: "网络请求超时，请检查你的网路情况",
            type: ConfirmTypeEnum.Error,
          });
        }
        return Promise.reject(error);
      }
    );
    return api;
  }, [params?.timeout]);

  const post = useCallback(async <T = any>(url: string, data: any) => {
    setIsLoading(true);
    const axios = myAxios();
    //@ts-ignore
    const response = await axios.post<IResult<T>>(url, data);
    setIsLoading(false);
    if (params?.responseAll == true) {
      return response;
    }
    if (response.data) {
      const { code, msg, data } = response.data;
      if (code == ResponseCode.Success && data) {
        setData(data);
        return data;
      }
      if (code == ResponseCode.Success && msg) {
        if (params?.isEnableDefaultMsg) {
          confirm?.show({ content: msg });
        }
        return true;
      }
      if (code == ResponseCode.JWT001 || code == ResponseCode.JWT000) {
        confirm?.show({ content: "登录超时", type: ConfirmTypeEnum.Error });
        return undefined;
      }
      if (!params?.isDisableTip) {
        if (msg) {
          confirm?.show({ content: msg, type: ConfirmTypeEnum.Error });
          return undefined;
        }
      }
    }
    return undefined;
  }, []);

  const uploadFile = useCallback(async (url: string, file: File) => {
    setIsLoading(true);
    const axios = myAxios();
    const response = await axios.post<IResult<string>>(
      url,
      { file },
      {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }
    );
    setIsLoading(false);
    if (response.data) {
      const { code, msg, data } = response.data;
      if (code == ResponseCode.Success && data) {
        return data;
      }
      if (code == ResponseCode.JWT001 || code == ResponseCode.JWT000) {
        confirm?.show({ content: "登录超时", type: ConfirmTypeEnum.Error });
        return undefined;
      }
      if (msg) {
        confirm?.show({ content: msg, type: ConfirmTypeEnum.Error });
        return undefined;
      }
    }
    return undefined;
  }, []);

  return {
    isLoading,
    uploadFile,
    post,
    data,
    setData,
  };
};
