// export const RequestUrl = "http://47.117.166.87:15000/kooola/create";
// export const DomainUrl = "https://localhost:3000";

// export const RequestUrl = "https://createhuidu.kooola.io/rhd/kooola/create";
// export const DomainUrl = "https://createhuidu.kooola.io";

export enum WebsiteVersionEnum {
  IO = "io",
  COM = "com",
}
export const RequestUrl = process.env.NEXT_PUBLIC_REQUESTURL + "";
export const DomainUrl = process.env.NEXT_PUBLIC_DOMAINURL + "";
export const NFCWS = process.env.NEXT_PUBLIC_NFCURL + "";
export const WebsiteVersion = process.env.NEXT_PUBLIC_WEBSITE_VERSION + "";
export const Version = process.env.NEXT_PUBLIC_VERSION + "";
