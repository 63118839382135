import { Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "nextjs-breadcrumbs";
import styles from "./MyBreadcrumbs.module.scss";
import { useRouter } from "next/router";
const myBreadcrumbsNameConvert = () => {
  return [
    { from: "goodsdetailother", to: "商品详情" },
    { from: "goodsstockdetail", to: "持有中" },
    { from: "todo", to: "代办事项" },
    {
      from: "add",
      to: "添加",
    },
    { from: "brand", to: "品牌" },
    { from: "detail", to: "详情" },
    { from: "collection", to: "系列" },
    { from: "goods", to: "商品" },
    { from: "blindbox", to: "发售" },
    { from: "face", to: "捏脸" },
    { from: "stock", to: "库存" },
    { from: "nftlist", to: "NFT列表" },
    { from: "subaccount", to: "子账号" },
    { from: "goodsdetail", to: "持有中" },
    { from: "gallery", to: "橱窗" },
  ];
};

export function MyBreadcrumbs() {
  const router = useRouter();
  return (
    <>
      {router.pathname == "/" ? (
        <></>
      ) : (
        <Grid container sx={{ mb: 2, padding: 2 }}>
          <Grid item md={12} xs={12}>
            <Stack direction={"row"} alignItems={"center"}>
              <IconButton
                onClick={() => {
                  router.push("/");
                }}
              >
                <HomeIcon />
              </IconButton>
              <Breadcrumbs
                omitRootLabel={true}
                listClassName={styles.breadcrumbs}
                replaceCharacterList={myBreadcrumbsNameConvert()}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
}
