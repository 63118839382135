import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Snackbar,
  Stack,
} from "@mui/material";
import React, { FC, useCallback, useContext, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

export enum MessageTypeEnum {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

interface IMessageProps {
  title?: string | React.ReactNode;
  content: string | React.ReactNode;
  type?: MessageTypeEnum;
  onOk?: (params: {
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  }) => Promise<void>;
  onCancel?: () => Promise<void>;
  okText?: string | React.ReactNode;
  cancelText?: string | React.ReactNode;
  isShowCancel?: boolean;
}

interface IMessageContext {
  show(params: IMessageProps): void;
}

const MyMessage: FC<
  Omit<IMessageProps, "content"> & {
    children: React.ReactNode;
    open: boolean;
    handleClose(): void;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  }
> = ({
  open,
  handleClose,
  children,
  type,
  title,
  okText,
  cancelText,
  onOk,
  onCancel,
  isLoading,
  setIsLoading,
  isShowCancel,
}) => {
  return (
    <Dialog open={open} maxWidth={false}>
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {title}
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: "640px" }}>{children}</DialogContent>
      {isLoading && <LinearProgress />}
      <DialogActions>
        {isShowCancel && (
          <Button
            variant={"outlined"}
            onClick={async () => {
              if (onCancel) {
                onCancel();
              }
              handleClose();
            }}
            disabled={isLoading}
          >
            {cancelText}
          </Button>
        )}

        <LoadingButton
          loading={isLoading}
          variant={"contained"}
          onClick={async () => {
            if (onOk) {
              await onOk({ isLoading, setIsLoading });
            }
            handleClose();
          }}
        >
          {okText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const MyMessageContext = React.createContext<
  IMessageContext | undefined
>(undefined);

export const MyMessageProvider = (props: any) => {
  const [isShowCancel, setIsShowCancel] = useState<boolean>(true);
  const [title, setTitle] = useState<string | React.ReactNode>("");
  const [content, setContent] = useState<string | React.ReactNode>("");
  const [type, setType] = useState<MessageTypeEnum>(MessageTypeEnum.Success);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [okText, setOkText] = useState<string | React.ReactNode>();
  const [cancelText, setCancelText] = useState<string | React.ReactNode>();
  const [onOk, setOnOk] =
    useState<
      (params: {
        isLoading: boolean;
        setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
      }) => Promise<void>
    >();
  const [onCancel, setOnCancel] = useState<() => Promise<void>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const show = useCallback(
    (params: IMessageProps) => {
      setTitle(params.title ?? "提示");
      setContent(params.content);
      setType(params.type ?? MessageTypeEnum.Success);
      setIsOpen(true);
      setOkText(params.okText ?? "确定");
      setCancelText(params.cancelText ?? "取消");
      setOnOk(() => params.onOk);
      setOnCancel(() => params.onCancel);
      setIsShowCancel(params.isShowCancel ?? true);
    },
    [isOpen == false]
  );
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen == true]);
  return (
    <MyMessageContext.Provider value={{ show }}>
      <MyMessage
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        open={isOpen}
        handleClose={handleClose}
        type={type}
        title={title}
        okText={okText}
        cancelText={cancelText}
        onOk={onOk}
        onCancel={onCancel}
        isShowCancel={isShowCancel}
      >
        {content}
      </MyMessage>
      {props.children}
    </MyMessageContext.Provider>
  );
};

export const useMyMessage = () => {
  const context = useContext(MyMessageContext);
  return context;
};
