import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { DataGrid, zhCN } from "@mui/x-data-grid";

export const themeOptions = createTheme(
  {
    palette: {
      mode: "dark",
      primary: {
        main: "#45ffb1",
      },
      secondary: {
        main: "#45ffb1",
      },
      background: {
        default: "#1b2033",
        paper: "#202940",
      },
      error: {
        main: "#ff3566",
      },
      warning: {
        main: "#ffa621",
      },
      success: {
        main: "#45ffb1",
      },
    },
  },
  zhCN
);

export const richTextThemeOptions = createTheme(
  {
    palette: {
      mode: "dark",
      primary: {
        main: "#45ffb1",
      },
      secondary: {
        main: "#45ffb1",
      },
      background: {
        default: "white",
        paper: "white",
      },
      error: {
        main: "#ff3566",
      },
      warning: {
        main: "#ffa621",
      },
      success: {
        main: "#45ffb1",
      },
    },
  },
  zhCN
);
