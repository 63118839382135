import { Button, Dialog } from "@material-ui/core";
import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import { MyConfirmControllerSingle } from "./MyConfirmController";
import closePic from "../../../public/assets/picture/dialog/close.svg";
import styles from "./MyConfirm.module.scss";
import Image from "next/image";
export const MyConfirm = observer(() => {
  const controller = useLocalObservable(() =>
    MyConfirmControllerSingle.createInstance()
  );
  const close = () => {
    controller.visible = false;
  };
  return (
    <>
      <Dialog
        open={controller.visible}
        keepMounted
        onClose={close}
        BackdropProps={{ style: { backdropFilter: "blur(20px)" } }}
        PaperProps={{
          style: { borderRadius: 20, overflow: "visible" },
        }}
      >
        <div className={styles.container}>
          {controller.title &&
            controller.title !== "Prompt" &&
            controller.title !== "提示" && (
              <div className={styles.title}>
                <div>{controller.title}</div>
                <div className={styles.close}>
                  <Image src={closePic} onClick={() => close()} />
                </div>
              </div>
            )}
          <div className={styles.main}>{controller.content}</div>
          {(controller.isShowCancel || controller.isShowOk) && (
            <div className={styles.footer}>
              {controller.isShowCancel && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    controller.onCancel();
                    controller.visible = false;
                  }}
                >
                  {controller.cancelText}
                </Button>
              )}
              {controller.isShowOk && (
                <Button
                  sx={{
                    textTransform:"unset"
                  }}
                  variant="contained"
                  onClick={() => {
                    controller.onOk();
                    controller.visible = false;
                  }}
                >
                  {controller.okText}
                </Button>
              )}
            </div>
          )}
        </div>
        {/* <DialogTitle>{controller.title}</DialogTitle> */}
      </Dialog>
    </>
  );
});
