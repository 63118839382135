import { Alert, Snackbar } from "@mui/material";
import React, { FC, useCallback, useContext, useState } from "react";

export enum ConfirmTypeEnum {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

interface IConfirmContext {
  show(params: {
    content: string | React.ReactNode;
    type?: ConfirmTypeEnum;
  }): void;
}

const MyConfirm: FC<{
  children: React.ReactNode;
  open: boolean;
  handleClose(): void;
  type: ConfirmTypeEnum;
}> = ({ open, handleClose, children, type }) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
        {children}
      </Alert>
    </Snackbar>
  );
};

export const MyConfirmContext = React.createContext<
  IConfirmContext | undefined
>(undefined);

export const MyConfirmProvider = (props: any) => {
  const [content, setContent] = useState<string | React.ReactNode>("");
  const [type, setType] = useState<ConfirmTypeEnum>(ConfirmTypeEnum.Success);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const show = useCallback(
    (params: { content: string | React.ReactNode; type?: ConfirmTypeEnum }) => {
      setContent(params.content);
      setType(params.type ?? ConfirmTypeEnum.Success);
      setIsOpen(true);
    },
    [isOpen == false]
  );
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen == true]);
  return (
    <MyConfirmContext.Provider value={{ show }}>
      <MyConfirm open={isOpen} handleClose={handleClose} type={type}>
        {content}
      </MyConfirm>
      {props.children}
    </MyConfirmContext.Provider>
  );
};

export const useMyConfirm = () => {
  const context = useContext(MyConfirmContext);
  return context;
};
