import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import React from "react";
import { Observer, observer, useLocalObservable } from "mobx-react-lite";
import { MyMessageControllerSingle } from "./MyMessageController";
import styles from "./MyMessage.module.scss";

export const MyMessage = () => {
  const controller = useLocalObservable(() =>
    MyMessageControllerSingle.createInstance()
  );
  const close = () => (controller.visible = false);
  return (
    <Observer>
      {() => (
        <Snackbar
          open={controller.visible}
          autoHideDuration={3000}
          onClose={close}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={close}
            severity={controller.type as any}
            sx={{ width: "100%" }}
          >
            <div className={styles.main}>{controller.content}</div>
          </Alert>
        </Snackbar>
      )}
    </Observer>
  );
};
