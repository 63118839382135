export type ApiEnum =
  | FileApiEnum
  | BrandApiEnum
  | CollectionApiEnum
  | SelectApiEnum
  | GoodsApiEnum
  | ActivityApiEnum
  | WhitelistApiEnum
  | SubAccountApiEnum
  | AuthorizeApiEnum
  | GalleryApiEnum
  | FaceApiEnum;

export enum FileApiEnum {
  /**
   * @description video image arweaveoss
   */
  Upload = "upload/arweave/step1",
  /**
   * @description glb usdz mp3 阿里云oss
   */
  UploadOther = "/upload/file",
  UploadOtherNew = "/upload/fileRetSize",
}

export enum SelectApiEnum {
  Brand = "queryCondition/brandList",
  //BrandAndCollection = "queryCondition/brandAndCollection",
  BrandAndCollection = "brand/list2",
}

export enum BrandApiEnum {
  BrandCreate = "brand/create",
  BrandGetList = "brand/list",
  BrandGetDetail = "brand/detail",
  BrandCheckSymbol = "brand/check/symbol",
}

// conllection/update/inner
// conllection/update/outer

export enum CollectionApiEnum {
  GetList = "collection/list",
  GetDetail = "collection/detail",
  CreateInner = "collection/create/inner",
  CreateOuter = "collection/create/outer",
  SearchOtherCollection = "collection/otter/detail",
  // {
  //   "blockChain" : "sol"
  //   ｝
  MerchantBcAddress = "collection/get/merchantBcAddress",
}

export enum GoodsApiEnum {
  FirstAndSecondCategory = "queryCondition/firstAndSecondCategory",
  Create = "skc/create",
  List = "skc/list",
  GetList = "/devScan/skc/get/list",
  Stock = "/skc/skcStockAdminMintList",
  Detail = "skc/detail",
  Update = "skc/update",
  Attribute = "skc/getAttribute",
  Mint = "skc/mint",
  Metadata = "skc/updateSkcMetadata",
  GetMetadata = "skc/getSkcMetadata",
  NFTList = "skc/getNftList",
  NFTListNew = "devScan/nftListData",
  NFCMint = "devScan/mint",
  NFCClean = "/devScan/mintClean",
  NFCTotalCopy = "/devScan/checkCopy",
  NFCTotalClean = "/devScan/checkClean",
  NFTAll = "devScan/nfcListGetSkcData",
}

export enum GalleryApiEnum {
  Detail = "/activity/shopWindow/detail",
  GoodsList = "/activity/shopWindow/goods/list",
  Create = "/activity/shopWindow/create",
  List = "/activity/shopWindow/list",
  Delete = "/activity/shopWindow/delete",
  On = "activity/shopWindow/onShelf",
  Update = "activity/shopWindow/update",
  confirm = "activity/shopWindow/onShelf/confirm",
}

export enum ActivityApiEnum {
  Create = "activity/create",
  List = "activity/list",
  Detail = "activity/detail",
  Update = "activity/update",
  ActivityList = "activity/goods/list",
}

export enum WhitelistApiEnum {
  List = "activity/whitelist/getList",
}

export enum FaceApiEnum {
  UploadFile = "upload/arweave/step1",
  Create = "test/customizationFace",
  List = "test/customizationFace/list",
  Mint = "test/contract/mint721",
}

export enum SubAccountApiEnum {
  Validate = "subAccount/queryUserAccount",
  List = "subAccount/getUserAccountList",
  AddDetail = "subAccount/addDetail",
  UpdateDetail = "subAccount/updateDetail",
  AddRole = "subAccount/addIncSkc",
  UpdateRole = "subAccount/updateIncSkc",
  toggleStatus = "subAccount/setStatus",
}

export enum AuthorizeApiEnum {
  Role = "privilege/getUserPrivilegeDetail",
}
