import { useTranslation } from "next-i18next";
import React from "react";
import commonJson from "../public/locales/zh-Hans/common.json";
export const useLocale: () => {
  t: (value: keyof typeof commonJson) => string;
  language: string;
} = () => {
  const { t, i18n } = useTranslation("common");

  return {
    language: i18n.language,
    t,
  };
};
