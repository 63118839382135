import { makeAutoObservable } from "mobx";
import { IMyMessageController } from "../../../interface/component/feedback/mymessage/IMyMessageController";
import { MessageEnum } from "../../../interface/utils/message/enum/MessageEnum";

class MyMessageController implements IMyMessageController {
  public visible: boolean;
  public confirmVisible: boolean;
  public type: MessageEnum;
  public content: string | JSX.Element;
  constructor() {
    this.visible = false;
    this.confirmVisible = false;
    this.type = MessageEnum.Info;
    this.content = "";
    makeAutoObservable(this);
  }

  showMessage(message?: { content?: string; type?: MessageEnum }): void {
    this.content = message?.content ?? "";
    this.type = message?.type ?? MessageEnum.Info;
    this.visible = true;
  }
}

export class MyMessageControllerSingle {
  private static controller: IMyMessageController;
  private constructor() {}
  public static createInstance():IMyMessageController {
    if (this.controller === undefined) {
      this.controller = new MyMessageController();
    }
    return this.controller;
  }
}
