export enum RouterPath {
  Login = "/login",
  Index = "/",
  Brand = "/brand",
  BrandDetail = "/brand/branddetail",
  Collection = "/collection",
  CollectionDetail = "/collection/collectiondetail",
  GoodsDetail = "/goods/goodsdetail",
  GoodsDetailOther = "/goods/goodsdetailother",
  Goods = "/goods",
  BlindBox = "/blindbox",
  BlindBoxDetail = "/blindbox/blindboxdetail",
  Face = "/face",
  SubAccount = "/subaccount",
  FaceDetail = "/face/facedetail",
  GoodsStockDetail = "/goods/goodsstockdetail",
  GoodsNftList = "/goods/goodsnftlist",
  Gallery = "/gallery",
  GalleryDetail = "/gallery/detail",
}
