import { useTranslation } from "next-i18next";
import React, { FC } from "react";
import commonJson from "../../public/locales/zh-Hans/common.json";

export const MyLocale: FC<{
  value: keyof typeof commonJson;
}> = ({ value }) => {
  const { t } = useTranslation("common");
  return <>{t(value)}</>;
};

export const MyLocaleR: FC<{
  transfer: (t: (value: keyof typeof commonJson) => string) => React.ReactNode;
}> = ({ transfer }) => {
  console.log("123")
  const { t } = useTranslation("common");
  console.log(t("signin"))
  return <>{transfer(t)}</>;
};




