import React, { useEffect, useMemo } from "react";
import { useMyApi } from "../api/useMyApi";
import { AuthorizeApiEnum } from "../../api/ApiEnum";
import { Console } from "console";

export function useAuthorize() {
  const { data, post,isLoading } = useMyApi();

  useEffect(() => {
    post(AuthorizeApiEnum.Role, {});
  }, []);

  const isWM = useMemo(
    () => data && data.roles.some((item: any) => item.roleCode == "WM"),
    [data]
  );

  return {
    isWM,
    isLoading,
  };
}
