//@ts-nocheck
import React from "react";
import "../styles/globals.css";
import styles from "./_app.module.scss";
import {
  Collapse,
  Container,
  Grid,
  Paper,
  Stack,
  ThemeProvider,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { RouterPath } from "../enum/RouterPath";
import { themeOptions } from "../theme/theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { styled, Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { appWithTranslation } from "next-i18next";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountMenu from "../component/layout/header";
import { SessionProvider, useSession } from "next-auth/react";
import { AppBar, Drawer, DrawerHeader } from "../styles/app.styled";
import { makeAutoObservable } from "mobx";
import { Observer, useLocalObservable } from "mobx-react-lite";
import { MyBreadcrumbs } from "../component/breadcrumbs/MyBreadcrumbs";
import { MyMessage } from "../component/feedback/mymessage/MyMessage";
import { MyConfirm } from "../component/feedback/myconfirm/MyConfirm";
import { MyConfirmProvider } from "../context/ConfirmContext";
import { MyMenu } from "../component/menu/MyMenu";
import logoPic from "../public/assets/picture/admin/logo.png";
import Image from "next/image";
import { MyMessageProvider } from "../context/MessageContext";
import CategoryOutlinedIcon from "@mui/icons-material/Category";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmark";
import StarsIcon from "@mui/icons-material/Stars";
import EventIcon from "@mui/icons-material/Event";
import FaceIcon from "@mui/icons-material/Face";
import { useAuthorize } from "../hooks/authorize/useAuthorize";
import { Version, WebsiteVersion, WebsiteVersionEnum } from "../config/config";
class AppVO {
  isOpen: boolean;
  constructor() {
    this.isOpen = true;
    makeAutoObservable(this);
  }
  open() {
    this.isOpen = true;
  }
  close() {
    this.isOpen = false;
  }
}

class AppController {
  vo: AppVO;
  constructor() {
    this.vo = new AppVO();
  }
}

class AppControllerSingle {
  private static appController: AppController;
  private constructor() {}
  public static createInstance() {
    if (this.appController === undefined) {
      this.appController = new AppController();
    }
    return this.appController;
  }
}

//@ts-ignore
function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const { isWM, isLoading } = useAuthorize();
  const theme = useTheme();
  const controller = useLocalObservable(() =>
    AppControllerSingle.createInstance()
  );
  const router = useRouter();

  if (isWM) {
    if (router.pathname.indexOf(RouterPath.Goods) == -1) {
      router.push(RouterPath.Goods);
    }
  }

  if (router.pathname == RouterPath.Login) {
    return (
      <SessionProvider session={session}>
        <ThemeProvider theme={themeOptions}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MyConfirmProvider>
              <Component {...pageProps} />
            </MyConfirmProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </SessionProvider>
    );
  }

  return (
    <Observer>
      {() => (
        <SessionProvider session={session}>
          <ThemeProvider theme={themeOptions}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MyConfirmProvider>
                <MyMessageProvider>
                  <Box sx={{ display: "flex" }}>
                    <CssBaseline />
                    <MyAppbar />
                    <Drawer variant="permanent" open={controller.vo.isOpen}>
                      <DrawerHeader>
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Box padding={3}>
                            <Stack alignItems={"center"} spacing={1}>
                              <Image src={logoPic} height={37} width={114} />
                              <div>{Version}</div>
                            </Stack>
                          </Box>
                          <IconButton
                            onClick={() => {
                              controller.vo.close();
                            }}
                          >
                            {theme.direction === "rtl" ? (
                              <ChevronRightIcon />
                            ) : (
                              <ChevronLeftIcon />
                            )}
                          </IconButton>
                        </Stack>
                      </DrawerHeader>
                      <Divider />
                      {isLoading ? (
                        <div></div>
                      ) : (
                        <List>
                          {isWM ? (
                            <MyMenu
                              icon={<StarsIcon />}
                              selected={
                                router.pathname == RouterPath.Goods ||
                                router.pathname == RouterPath.GoodsDetail
                              }
                              onClick={() => router.push(RouterPath.Goods)}
                              text="商品"
                            ></MyMenu>
                          ) : (
                            <>
                              {WebsiteVersion == WebsiteVersionEnum.IO && (
                                <MyMenu
                                  icon={<CollectionsBookmarkOutlinedIcon />}
                                  text="系列"
                                  selected={
                                    router.pathname == RouterPath.Collection ||
                                    router.pathname ==
                                      RouterPath.CollectionDetail
                                  }
                                  onClick={() =>
                                    router.push(RouterPath.Collection)
                                  }
                                ></MyMenu>
                              )}
                              <MyMenu
                                icon={<CategoryOutlinedIcon />}
                                text="品牌"
                                selected={
                                  router.pathname == RouterPath.Brand ||
                                  router.pathname == RouterPath.BrandDetail
                                }
                                onClick={() => router.push(RouterPath.Brand)}
                              ></MyMenu>
                              <MyMenu
                                icon={<StarsIcon />}
                                selected={
                                  router.pathname == RouterPath.Goods ||
                                  router.pathname == RouterPath.GoodsDetail
                                }
                                onClick={() => router.push(RouterPath.Goods)}
                                text="商品"
                              ></MyMenu>
                              <MyMenu
                                icon={<EventIcon />}
                                text="发售"
                                selected={
                                  router.pathname == RouterPath.BlindBox ||
                                  router.pathname == RouterPath.BlindBoxDetail
                                }
                                onClick={() => router.push(RouterPath.BlindBox)}
                              ></MyMenu>
                               <MyMenu
                                icon={<EventIcon />}
                                text="橱窗"
                                selected={
                                  router.pathname == RouterPath.Gallery ||
                                  router.pathname == RouterPath.GalleryDetail
                                }
                                onClick={() => router.push(RouterPath.Gallery)}
                              ></MyMenu>
                              {/* <MyMenu
                                icon={<FaceIcon />}
                                text="捏脸"
                                selected={
                                  router.pathname == RouterPath.Face ||
                                  router.pathname == RouterPath.FaceDetail
                                }
                                onClick={() => router.push(RouterPath.Face)}
                              ></MyMenu> */}
                              <MyMenu
                                icon={<FaceIcon />}
                                text="子账号"
                                selected={
                                  router.pathname == RouterPath.SubAccount
                                }
                                onClick={() =>
                                  router.push(RouterPath.SubAccount)
                                }
                              ></MyMenu>
                            </>
                          )}
                        </List>
                      )}
                    </Drawer>
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                      <DrawerHeader />

                      <MyBreadcrumbs />
                      <Component {...pageProps} />
                    </Box>
                  </Box>
                  <Observer>
                    {() => (
                      <>
                        <MyMessage {...pageProps} />
                        <MyConfirm {...pageProps} />
                      </>
                    )}
                  </Observer>
                </MyMessageProvider>
              </MyConfirmProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </SessionProvider>
      )}
    </Observer>
  );
}

const MyAppbar = () => {
  const { data, status } = useSession();
  const router = useRouter();
  const controller = useLocalObservable(() =>
    AppControllerSingle.createInstance()
  );
  return (
    <Observer>
      {() => (
        <AppBar
          style={{ zIndex: 99 }}
          position="fixed"
          enableColorOnDark={true}
          //@ts-ignore
          open={controller.vo.isOpen}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => controller.vo.open()}
              edge="start"
              sx={{
                marginRight: 5,
                opacity: 1,
                ...(controller.vo.isOpen && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography component={"div"} sx={{ flexGrow: "1" }}>
              {data?.ownerId} 用户， 欢迎来到 Kooola Create
            </Typography>
            <AccountMenu />
          </Toolbar>
        </AppBar>
      )}
    </Observer>
  );
};

export default appWithTranslation(MyApp);
