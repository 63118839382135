import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { FC, useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

interface IMyMenuItemProps {
  onClick?: () => void;
  isOpen?: boolean;
  icon?: React.ReactNode;
  text?: string;
  children?: React.ReactNode;
  selected?: boolean;
}

export const MyMenu: FC<IMyMenuItemProps> = ({
  isOpen,
  onClick,
  icon,
  text,
  children,
  selected,
}) => {
  const [open, setOpen] = useState<boolean>(isOpen ?? false);
  return (
    <>
      <ListItemButton
        onClick={() => {
          if (children) {
            setOpen(!open);
          }
          if (onClick) {
            onClick();
          }
        }}
        selected={selected}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={text ?? ""} />
        {children && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" color="secondary.main">
          {children}
        </List>
      </Collapse>
    </>
  );
};
